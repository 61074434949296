exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-approachable-bookkeeping-js": () => import("./../../../src/pages/approachable-bookkeeping.js" /* webpackChunkName: "component---src-pages-approachable-bookkeeping-js" */),
  "component---src-pages-cases-js": () => import("./../../../src/pages/cases.js" /* webpackChunkName: "component---src-pages-cases-js" */),
  "component---src-pages-discovering-surplus-js": () => import("./../../../src/pages/discovering-surplus.js" /* webpackChunkName: "component---src-pages-discovering-surplus-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-more-users-less-waste-js": () => import("./../../../src/pages/more-users-less-waste.js" /* webpackChunkName: "component---src-pages-more-users-less-waste-js" */),
  "component---src-pages-netlight-js": () => import("./../../../src/pages/netlight.js" /* webpackChunkName: "component---src-pages-netlight-js" */),
  "component---src-pages-operating-js": () => import("./../../../src/pages/operating.js" /* webpackChunkName: "component---src-pages-operating-js" */),
  "component---src-pages-portfolio-js": () => import("./../../../src/pages/portfolio.js" /* webpackChunkName: "component---src-pages-portfolio-js" */),
  "component---src-templates-portfolio-template-js": () => import("./../../../src/templates/portfolioTemplate.js" /* webpackChunkName: "component---src-templates-portfolio-template-js" */)
}

